<!-- eslint-disable import/no-cycle -->
<template>
  <div>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="TruckIcon"
          statistic="2"
          statistic-title="Camions"
          color="info"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="UsersIcon"
          statistic="12"
          statistic-title="Transporteurs"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="dark"
          icon="FilterIcon"
          statistic="97"
          statistic-title="Opportunités de fret"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="FileTextIcon"
          statistic="26"
          statistic-title="Contrats actifs"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="TrendingUpIcon"
          statistic="68"
          statistic-title="Devis soumis"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="primary"
          icon="CheckIcon"
          statistic="2"
          statistic-title="Devis sous contrat"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="FileTextIcon"
          statistic="3"
          statistic-title="Contrats clôturés"
          color="danger"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="CheckSquareIcon"
          statistic="3"
          statistic-title="Devis clôturés"
          color="danger"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="TrendingDownIcon"
          statistic="3"
          statistic-title="Devis rejetés"
          color="danger"
        />
      </b-col>
      
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
}
</script>
